// Bootstrap imports
import Alert from 'bootstrap/js/dist/alert';
import Popover from 'bootstrap/js/dist/popover';

/**
 * Maximum file size allowed for uploads (10MB)
 */
export const MAX_FILE_SIZE = 10 * 1024 * 1024;

/**
 * Dismiss alerts automatically after 3 seconds
 */
export function initAutoDismissAlerts() {
  const alertList = document.querySelectorAll('.alert-dismissible');
  alertList.forEach(function (alert) {
    setTimeout(function () {
      const bsAlert = Alert.getOrCreateInstance(alert);
      if (bsAlert !== null) {
        bsAlert.close();
      }
    }, 3000);
  });
}

/**
 * Initialize Bootstrap popovers
 */
export function initPopovers() {
  const popoverElements = document.querySelectorAll('[data-bs-toggle="popover"]');
  popoverElements.forEach((popoverElement) => {
    new Popover(popoverElement);
  });
}

/**
 * Initialize fullscreen toggle functionality
 */
export function initFullscreenToggle() {
  const fullScreenToggle = document.getElementById('fullScreenToggle');
  const mainContainer = document.querySelector('main .container');

  if (!fullScreenToggle || !mainContainer) {
    // If either element is not found, exit the function
    return;
  }

  function toggleFullscreen() {
    mainContainer.classList.toggle('container');
    mainContainer.classList.toggle('container-fluid');

    const isFullScreen = mainContainer.classList.contains('container-fluid');
    fullScreenToggle.setAttribute('aria-pressed', isFullScreen);

    const icon = fullScreenToggle.querySelector('i');
    if (icon) {
      if (isFullScreen) {
        icon.classList.remove('bi-arrows-fullscreen');
        icon.classList.add('bi-fullscreen-exit');
      } else {
        icon.classList.remove('bi-fullscreen-exit');
        icon.classList.add('bi-arrows-fullscreen');
      }
    }

    localStorage.setItem('containerPreference', isFullScreen ? 'fluid' : 'fixed');
  }

  fullScreenToggle.addEventListener('click', toggleFullscreen);

  // Check localStorage for saved preference
  const savedPreference = localStorage.getItem('containerPreference');
  if (savedPreference === 'fluid') {
    toggleFullscreen();
  }
}

/**
 * Adds file size validation to a file input element
 * @param {HTMLInputElement} fileInput - The file input element to validate
 */
export function setupFileSizeValidation(fileInput) {
  if (!fileInput) {
    console.error('File input element not found. Cannot setup validation.');
    return;
  }

  // Calculate max size in MB for display in error message
  const maxSizeInMB = (MAX_FILE_SIZE / (1024 * 1024)).toFixed(0);

  fileInput.addEventListener('change', (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Remove existing warning if any
    let warningMessageDiv = document.getElementById('fileSizeWarning');
    if (warningMessageDiv) {
      warningMessageDiv.remove();
    }

    if (file.size > MAX_FILE_SIZE) {
      e.target.value = ''; // clear the file input

      // Calculate file size in megabytes
      const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);

      // Create warning message with Bootstrap styling
      warningMessageDiv = document.createElement('div');
      warningMessageDiv.id = 'fileSizeWarning';
      warningMessageDiv.className = 'alert alert-danger mt-2';
      warningMessageDiv.textContent = `Le fichier sélectionné fait ${fileSizeInMB}Mo, ce qui dépasse la limite de ${maxSizeInMB} Mo. Merci de choisir un fichier plus petit.`;

      // Insert the warning message below the file input field
      e.target.parentElement.appendChild(warningMessageDiv);
    }
  });
}
